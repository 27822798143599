export const constants = {
    id : "#",
    status:"Status",
    lessonType:"Lesson type",
    technologies:"Technologies",
    whatHappened:"What happened?",
    howDidYouResolve:"How did you resolve?",
    foundByWhom:"Found by whom",
    projectName:"Project name",
    actions:"ACtions",
    open:"Open",
    close:"Close",
    programmatic:"Programmatic",
    logical:"Logical",
    technical:"Technical",
    functional:"Functional",
    others:"Others",
    title:"Lessons Learned Repository",
    actions:"Actions",
    deleteConfirmation:"Are you sure you want to delete this item?",
    add:"Add the Learned Lesson",
    edit:"Edit the Learned Lesson"
}
export const urls ={
    // REACT_APP_API_BASE_URL :"http://debug.teamta.net:5000/",

    REACT_APP_API_BASE_URL:"https://wisdomwarehouse.teamta.net",

    // REACT_APP_API_BASE_URL:"https://lessonlearnerstaging.teamta.net",

    REACT_APP_SSO_SERVER:"https://sso.kite.teamta.net", 

    // REACT_APP_SSO_APP_ID:"50c721bb-9990-4df1-b8b2-05e3f7fc11d0",
    REACT_APP_SSO_APP_ID:"fc745f66-cce3-4033-840f-45da3cb84b60",
    // REACT_APP_SSO_APP_ID:"38e94118-9052-4aef-adc6-0f4d69d8b3a7",

    // BACKEND_URL:"http://localhost:5000",
    BACKEND_URL:"https://wisdomwarehouse.teamta.net",
    // BACKEND_URL:"https://lessonlearnerstaging.teamta.net",
   
}
