import axios, { AxiosRequestConfig } from "axios";
import Cookies from 'js-cookie'
import Authstore from "../Store/Authstore";
import {urls} from '../Utils/constants'
class API {
//   authStore = new Authstore();
  constructor() {
    axios.defaults.withCredentials = true;
    axios.interceptors.request.use(
      (config) => {
        config.baseURL = urls.REACT_APP_API_BASE_URL;
        config.responseType = "json";
        const token = this.getToken();

        if (token) {
          const item = { ...config };
          item.headers.Authorization = `Bearer ${token}`;
          return item;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (responseInter) => {
        return responseInter;
      },
      (error) =>
        new Promise((resolve, reject) => {
          if (error?.response?.status === 401) {
            Authstore.signOut();
          }
          reject(error);

          if (error?.response?.status != 422) {
            console.log("error", error)
            window.location.href =
              urls.REACT_APP_SSO_SERVER +
              "/sso/auth/login?appId=" +
              urls.REACT_APP_SSO_APP_ID;
          }
        })
    );
  }

  getToken() {
    let token = Cookies.get('token');
    return token;
  }

  // async get(url) {
  //   return axios.get(url, config);
  // }

  async post(url, data) {
    return axios.post(url, data);
  }

  async put(url, data) {
    return axios.put(url, data);
  }

  // async delete(url,data) {
  //   return axios.delete(url, data);
  // }
}
export default new API();
